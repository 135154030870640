/* REPORTS ROUTES */

let base_url = process.env.VUE_APP_DOMAIN_LEARNING_PATHS;

if(base_url == 'http://127.0.0.1:8000' || base_url == 'http://localhost:8000'){
  base_url='https://dev.services.smileandlearn.net'
}
// if (base_url == 'https://dev.services.smileandlearn.net') base_url = 'http://127.0.0.1:8001'

const micro_v = '/paths/v1'
const micro_v2 = '/paths/v2'

/* Return all data of one specific content */
export const fetchContentDetails                = base_url + micro_v + '/market/contents/getContentDetails';

/*  */
export const fetchContentSearch                 = base_url + micro_v + '/market/contents/search';


/* Initial Data necesary to create a LP */
export const getCreateLearningPathInitialData   = base_url + micro_v + '/paths/get/createLearningPathInitialData';

/* Get LP Data by id */
export const getLearningPathDetails             = base_url + micro_v + '/paths/get/getLearningPathDetails';

/* Save Learning Path*/
export const saveLearningPath                   = base_url + micro_v + '/paths/get/saveLearningPath';

/* delete Learning Path*/
export const deleteLearningPath                 = base_url + micro_v + '/paths/get/deleteLearningPath';



/* save Allocation */
export const assignLearningPath                 = base_url + micro_v + '/paths/get/assignLearningPath';
/* get Dreamer LP Data */
export const dreamerLPAsignation                = base_url + micro_v + '/paths/get/dreamerLPAsignation';

/* NEW ENDPOINTS */
export const getContents                        = base_url + micro_v2 + '/contents';
export const getHierarchy                       = base_url + micro_v2 + '/hierarchy';
export const getLearningPath                    = base_url + micro_v2 + '/learningPaths';
export const getRecommendedLearningPath         = base_url + micro_v2 + '/learningPaths/recommended';
export const getAllocation                      = base_url + micro_v2 + '/allocation';
