<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ lp.name }}
        </h4>
      </div>

      <!-- Header: Right -->
       <div class="email-header-right ml-2 pl-1">
        <feather-icon
          class="cursor-pointer text-info"
          v-b-tooltip.hover.up="$t('learning_path.information')"
          icon="InfoIcon"
          size="17"
          @click.stop="$emit('showLPDetails',lp)"
        />
<!--
          <b-spinner v-if="loading"/>
          <feather-icon v-else
            v-b-tooltip.hover.up="$t('forms.save_changes')"
            icon="SaveIcon"
            size="17"
            class="cursor-pointer text-success"
            @click="$emit('save-allocation')"
          />-->
      </div> 
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >

      <!-- Email Thread -->
      <!-- <b-row>
        <b-col cols="12">
          <learning-path-detail-card 
            :lp="lp" />
        </b-col>
      </b-row> -->

      <b-row>
        <b-col cols="12">
          <h4 class="my-2">{{$t('Assign learning paths to students')}} </h4>

          <b-card no-body>
           <b-row>
              <b-col md="6" sm="12">
                <b-form-group
                class="mt-1 ml-2"
                  :label="$t('fields.group')"
                  label-for="group">
                  <v-select
                    v-model="group_selected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :getOptionLabel="group_selected => group_selected.id==0? $t('table.no_group') :group_selected.name"
                    :options="groups_options"
                  >
                    <template #option="{ id, name, id_avatar, linked }">
                      <b-avatar
                        rounded
                        :src="require('@/assets/images/avatar/groups/'+id_avatar+'.png?v4')"
                        size="16"
                        class="align-middle mr-50"
                        variant="light"
                      />
                      <span> {{ id == 0 ? $t('table.no_group') : name }}</span>
                      <b-badge pill variant="light-info">{{linked.length}} {{$t('Dreamers')}}</b-badge>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="table-responsive-sm">
              <table role="table" aria-busy="false" aria-colcount="4" class="table b-table" id="__BVID__3207">
                <thead role="rowgroup" class="">
                  <tr role="row" class="">
                    <th role="columnheader" scope="col" aria-colindex="0" class="cursor-pointer"
                     @click="resolveDreamerChecked('all') ?
                     group_selected 
                      ? $store.commit('learningPaths/allocateDreamer', [group_selected.linked.map(x=>x.id), lp.id, 'none'])
                      : $store.commit('learningPaths/allocateDreamer', [ [], lp.id, 'none'])
                    : group_selected 
                      ? $store.commit('learningPaths/allocateDreamer', [group_selected.linked.map(x=>x.id), lp.id, 'all'])
                      : $store.commit('learningPaths/allocateDreamer', [
                          Object.keys($store.getters['learningPaths/getDreamers']), lp.id, 'all'])
                     ">
                      <div>
                        <feather-icon
                          :icon="resolveDreamerChecked('all') ? 'CheckSquareIcon': 'SquareIcon'"
                          size="17"
                        />
                      </div>
                    </th>
                    <th role="columnheader" scope="col" aria-colindex="1" class=""><div>{{$t('Dreamers')}}</div></th>
                    <th role="columnheader" scope="col" aria-colindex="2" class=""><div>{{$t('reports.startDate')}}</div></th>
                    <th role="columnheader" scope="col" aria-colindex="3" class=""><div>{{$t('reports.endDate')}}</div></th>
                    <th role="columnheader" scope="col" aria-colindex="4" class="">
                      <div>
                        <b-button :disabled="!isThereAnyChildren" 
                          size="sm"
                          variant="secondary"
                          @click="datesAllModalShow = !datesAllModalShow" 
                          v-b-tooltip.hover.top="$t('learning_path.assign_date_to_all_dreamers')"
                        >
                          <feather-icon
                            icon="CalendarIcon"
                            size="14"
                          />
                        </b-button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  <tr role="row" class="" v-for="child in filtered_dreamers" :key="child.id">
                    <td aria-colindex="0" role="cell" class="cursor-pointer"
                      @click="$store.commit('learningPaths/allocateDreamer', [[child.id], lp.id, 'one'])">
                      <feather-icon
                          :icon="resolveDreamerChecked(child.id) ? 'CheckSquareIcon': 'SquareIcon'"
                          size="17"
                        />
                    </td>
                    <td aria-colindex="1" role="cell" class="cursor-pointer"
                      @click="$store.commit('learningPaths/allocateDreamer', [[child.id], lp.id, 'one'])"
                    >
                      <span class="d-flex justify-start align-center">
                        
                        <b-img
                            :src="require('@/assets/images/avatar/AvatarSprite_'+child.avatar+'.png')"
                            blank-color="#ccc"
                            height="30"
                            alt="placeholder"
                            class="mr-1 p-25"
                          />
                          {{child.name}}

                          <feather-icon v-if="resolveSuggestedDreamer(child.id)"
                            icon="StarIcon"
                            size="17"
                            class="ml-1 text-warning"
                            v-b-tooltip.hover.bottom="lp.explanation"
                          />
                          
                      </span>
                    </td>
                    <td aria-colindex="2" role="cell" class="">
                      {{resolveDreamerAllocation(child.id, 'start_date') ?new Date(resolveDreamerAllocation(child.id, 'start_date')).toLocaleDateString(geti18n):null}}
                    </td>
                    <td aria-colindex="3" role="cell" class="">
                      {{resolveDreamerAllocation(child.id, 'end_date') ?new Date(resolveDreamerAllocation(child.id, 'end_date')).toLocaleDateString(geti18n):null}}
                    </td>
                    <td aria-colindex="4" role="cell" class="">
                      <div style="width:100px;" v-if="resolveDreamerChecked(child.id)">
                        <b-button 
                          size="sm"
                          variant="secondary"
                          @click="datesModalShow = !datesModalShow; datesModalDreamer=child.id"
                          v-b-tooltip.hover.top="$t('learning_path.select_dates')"
                        >
                          <feather-icon
                            icon="CalendarIcon"
                            size="14"
                          />
                        </b-button>
                        <b-button v-if="resolveDreamerAllocation(child.id, 'start_date')||resolveDreamerAllocation(child.id, 'end_date')"
                          size="sm"
                          variant="danger"
                          class="ml-50"
                          @click="$store.commit('learningPaths/allocateDreamerDate', ['start_date', null, child.id, lp.id]); $store.commit('learningPaths/allocateDreamerDate', ['end_date', null, child.id, lp.id])"
                          v-b-tooltip.hover.top="$t('learning_path.remove_dates')"
                        >
                          <feather-icon
                            icon="XIcon"
                            size="14"
                          />
                        </b-button>
                      </div>
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </b-card>

            <!-- Modal para seleccionar las fechas por Dreamer -->
            <b-modal
              v-if="datesModalDreamer"
              v-model="datesModalShow"
              :title="$t('Dates for')+' '+this.$store.getters['learningPaths/getDreamers'][datesModalDreamer].name"
              :ok-title="$t('reports.continue')"
              :cancel-title="$t('clear')"
              ok-variant="primary"
              cancel-variant="flat-danger"
              @cancel="$store.commit('learningPaths/allocateDreamerDate', ['start_date', null, datesModalDreamer, lp.id]); $store.commit('learningPaths/allocateDreamerDate', ['end_date', null, datesModalDreamer, lp.id])"
            >
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('reports.startDate')"
                    :label-for="'startDate'+datesModalDreamer"
                  >
                  <b-form-datepicker
                    :id="'startDate'+datesModalDreamer"
                    :placeholder="$t('reports.select')"
                    :value="resolveDreamerAllocation(datesModalDreamer, 'start_date')"
                    start-weekday="1"
                    :locale="getLocal"
                    @input="(val) => $store.commit('learningPaths/allocateDreamerDate', ['start_date', val, datesModalDreamer, lp.id])"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :max="maxDate(resolveDreamerAllocation(datesModalDreamer, 'end_date'))"
                    reset-button
                    :label-reset-button="$t('learning_path.unselect_all')"
                  />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('reports.endDate')"
                    :label-for="'endDate'+datesModalDreamer"
                  >
                  <b-form-datepicker
                    :id="'endDate'+datesModalDreamer"
                    :placeholder="$t('reports.select')"
                    start-weekday="1"
                    :value="resolveDreamerAllocation(datesModalDreamer, 'end_date')"
                    :locale="getLocal"
                    @input="(val) => $store.commit('learningPaths/allocateDreamerDate', ['end_date', val, datesModalDreamer, lp.id])"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :min="minDate(resolveDreamerAllocation(datesModalDreamer, 'start_date'))"
                    reset-button
                    :label="$t('reports.endDate')"
                    :label-reset-button="$t('learning_path.unselect_all')"
                  />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-modal>

             <!-- Modal para seleccionar las fechas PARA TODOS los Dreamer -->
            <b-modal
              v-model="datesAllModalShow"
              :title="$t('Dates for all')"
              :ok-title="$t('reports.continue')"
              :cancel-title="$t('learning_path.delete_all_dates')"
              ok-variant="primary"
              @ok="handleAllocationDatesForAll('save')"
              cancel-variant="flat-danger"
              @cancel="handleAllocationDatesForAll('delete')"
            >
              <b-row>
                <b-col md="4" sm="12">
            <b-form-group
              :label="$t('reports.startDate')"
              label-for="startDate"
            >
              <b-form-datepicker
                id="startDate"
                :placeholder="$t('reports.select')"
                :value="startDate"
                start-weekday="1"
                :locale="getLocal"
                @input="(val) => handleAllocationDatesForAll('start', val)"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :max="maxDate(endDate)"
                reset-button
                :label-reset-button="$t('learning_path.unselect_all')"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" sm="12">
            <b-form-group
              :label="$t('reports.endDate')"
              label-for="endDate"
            >
              <b-form-datepicker
                id="endDate"
                :placeholder="$t('reports.select')"
                :value="endDate"
                start-weekday="1"
                :locale="getLocal"
                @input="(val) => handleAllocationDatesForAll('end', val)"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :min="minDate(startDate)"
                reset-button
                :label-reset-button="$t('learning_path.unselect_all')"
              />
            </b-form-group>
          </b-col>
              </b-row>
            </b-modal>

        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
  </div>





                  
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'

import {
  VBTooltip,
  BFormDatepicker, BSpinner, BAvatar,
  BImg, BButton, BButtonGroup, BFormGroup, BCardBody, BCardText, BDropdown, BDropdownItem, 
  BRow, BCol, BBadge, BCard, BLink,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import LearningPathDetailCard from './LearningPathDetailCard.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    vSelect,

    BImg, BButton, BButtonGroup, BFormGroup, BCardBody, BCardText, BDropdown,
    BDropdownItem, BSpinner,
    BFormDatepicker,
    BAvatar,
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,

    // 3rd Party
    VuePerfectScrollbar,

    LearningPathDetailCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  props: {
    loading: {
      type: Boolean,
      required:false,
      default: false
    },
    lp: {
      type: Object,
      required: true,
    },
    isAllocation:{
      type: Boolean,
      required: false,
      default: false
    },
    isReport:{
      type: Boolean,
      required: false,
      default: false
    },
    isItemChecked:{
      type:Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    return {
      perfectScrollbarSettings,
    }
  },
  data(){
    return {
      show_dates_selection : false,
      startDate: null,
      endDate: null,
      group_selected:null,

      datesModalShow: false,
      datesModalDreamer:null,

      datesAllModalShow: false
    }
  },
  computed:{
    groups_options(){
      return this.$store.getters['learningPaths/getDreamersByGroup']
    },
    getLocal(){
      return this.$store.getters['user/getLang']
    },
    geti18n(){
      return this.$store.getters['user/geti18n']
    },
    isThereAnyChildren(){
      if(this.$store.getters['learningPaths/getAllocation'][this.lp.id]){
        if(Object.keys(this.$store.getters['learningPaths/getAllocation'][this.lp.id]).length > 0){
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    filtered_dreamers () {
      if (this.group_selected) {
        return this.group_selected.linked
      } else {
        return this.$store.getters['learningPaths/getDreamers']
      }
    }
  },
  methods:{
    handleAllocationDatesForAll(action, val=null){
      switch (action) {
        case 'start': 
          this.startDate = val          
          break;
        case 'end': 
          this.endDate = val          
          break;
        case 'save':
          this.$store.commit('learningPaths/allocateDreamerDateAll', [this.startDate, this.endDate, this.lp.id])
          break;
        case 'delete':
          this.$store.commit('learningPaths/allocateDreamerDeleteAllDates', this.lp.id)
          break;
        default:
          break;
      }
    },
    maxDate(end_date){
      return end_date ?? null
    },
    minDate(start_date){
      return start_date ?? null
    },
    
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    resolveDreamerChecked(id){
      let alloc = []
      if(this.$store.getters['learningPaths/getAllocation'][this.lp.id]){
        alloc = Object.keys(this.$store.getters['learningPaths/getAllocation'][this.lp.id])
      }

      if(id == 'all'){

        let my_filtered_children = []
        if (this.group_selected) {
          my_filtered_children = this.group_selected.linked
        } else {
          my_filtered_children = Object.values(this.$store.getters['learningPaths/getDreamers'])
        }

        let complete = true
        my_filtered_children.map(child => {
          if(!alloc.includes(child.id.toString())){
            complete = false
          }  
        })
        return complete
      } else {
        if(alloc.includes(id.toString())){
          return true
        } else {
          return false
        }
      }
    },
    resolveSuggestedDreamer(child_id){
      if(this.lp.suggested_dreamers && this.lp.suggested_dreamers.length>0){
        return this.lp.suggested_dreamers.includes(child_id.toString())
      }
      return false
    },
    resolveDreamerAllocation(id, date){
      let alloc = []
        if(this.$store.getters['learningPaths/getAllocation'][this.lp.id]){
          alloc = Object.keys(this.$store.getters['learningPaths/getAllocation'][this.lp.id])
        }
      if(alloc.includes(id.toString())){
        return this.$store.getters['learningPaths/getAllocation'][this.lp.id][id][date]
      } else {
        return null
      }
    
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.activity-card{
  overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 3fr;
    
    .card-body[data-v-476fe675] {
    border-right: 1px solid #ebe9f1;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    }
}
.activity-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}
.item-img{
    height: 100%;
}

.dreamers-container{
  /* max-height: calc(100% - 150px); */
    overflow-y: auto;
    /* background-color: #fff;
    padding: 1.5rem; */
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
    gap: 1rem;
    margin-top: 10px;
}

.dreamers-container > div {
    background: #ede7f6;
    padding: 5px;
    border-radius: 1rem;
}
.dreamer-not-selected {
    filter: opacity(30%);
}

.dreamer-selected{
    border: 2px solid #003299;
}

.d-container {
    position: relative;
    width: 100%;
    max-width: 400px;
    padding: 0;
    margin-right: auto;
    margin-left: auto;

}

.dreamer-check {
    position: absolute;
    z-index: 2;
    color: #003299;
    font-size: 2rem;
    right: 0;
    top: 0;
}
.child-name {
    height: 48px;
    white-space: normal;
    overflow: hidden;
}
</style>
