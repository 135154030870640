<template>
<div>
  <div v-if="selected_lps.length==0">
    {{$t('learning_path.contents_missing')}}
  </div>
  <div v-else
    v-for="(data) in selected_lps"
    :key="data.id"
    class="d-flex justify-content-start align-items-center mt-1"
  >
    <div class="mr-1">
      <b-avatar
        size="40"
        :src="require('@/assets/images/learning_paths/'+data.img+'.png')"
        variant="light"
      />
    </div>
    <div class="profile-user-info">
      <h6 class="mb-0">
        {{ data.name }}
      </h6>
      <small class="text-muted">{{ data.description }}</small>
    </div>
    <div class="profile-star ml-auto">
      <b-button
          v-b-tooltip.hover.top="$t('learning_path.remove')"
          variant="light"
          type="button"
          @click="$emit('toggle-lp-in-list', data)"
        >
        <feather-icon
          icon="XIcon"
          size="16"
        />
        </b-button>
    </div>
  </div>
</div>
</template>

<script>
import {
    BAvatar, BButton, VBTooltip
} from 'bootstrap-vue'

export default {
  components:{
    BAvatar,
    BButton,
    VBTooltip,
  },
  props:{
    selected_lps: {
      type: Array,
      required: true
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
}
</script>

<style>

</style>