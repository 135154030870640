<template>
   <b-row
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="10"
      md="11"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 :class="['content-header-title float-left pr-1 mb-0 break-word']">
            {{ pageTitle }} 
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <!-- breadcrumb items -->
              <b-breadcrumb-item
                v-for="item in breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="{ name: item.to, params: item.params }"
                class="text-truncate"
              >
              {{ item.text }}
            </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-right d-block mb-1"
      cols="2"
      md="1"
    >
      <b-button :to="manageLink"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        class="btn-icon"
        style="padding:0.6rem;"
        v-b-tooltip.hover.bottom="`${$t('dash.cards.go_to')} ${$t('Help')}`"
      >
        <feather-icon icon="HelpCircleIcon" style="width: 20px; height: 20px;" />
      </b-button>

    </b-col>

  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  props:{
    pageTitle: {
      type: String,
      required: true
    },
    breadcrumb: {
      type: Array,
      required: true
    },
    helpLink:{
      type: Object, // { name: 'faq', params: ... }
      required:false
    }
  },
  computed:{
    manageLink(){
      if(this.helpLink) return this.helpLink
      return { name: 'faq' }
    }
  }
}
</script>
<style scoped>
@media (max-width: 648px) {
    .breadcrumbs-top .content-header-title {
        display: block !important;
    }
    .breadcrumb {
      display: flex;
      flex-wrap: wrap;
      font-size: 1rem;
      list-style: none;
  }
}
</style>