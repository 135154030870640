<template>
  <div>
    <custom-breadcrumb
        :pageTitle="$t('learning_path.assign_heading')"
        :breadcrumb="[
          {
            to: 'learning-paths',
            text: this.$t('Learning Paths')
          },
        ]"
      ></custom-breadcrumb>
      <b-row v-if="loading">
        <b-col cols="12"  >
          <div class="text-center">
            <b-spinner label="Loading..." />
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12" >
          <learning-path-list 
            :isAllocation="true" />
        </b-col>
      </b-row>
  </div>
</template>

<script>
import {
assignLearningPathInitialData, 
getAllocation,

//getAllLearningPaths,
getContents,
getHierarchy,
getLearningPath,
getRecommendedLearningPath
} from '@/api/learningpaths'
import { getGroup } from '@/api/routes'

import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import LearningPathList from './LearningPathList.vue'
import { 
  BSpinner, BRow, BCol, 
 } from 'bootstrap-vue'
export default {
  components:{
    CustomBreadcrumb,
    LearningPathList,

    BSpinner, BRow, BCol, 

  },
  data(){
    return {
      breadcrumb:[],
      loading: true
    }
  },
  created(){
    this.getData()
  },
  computed:{
    lang(){
      return this.$store.getters['user/getLang']
    }
  },
  watch:{
    lang(newLang, oldLang){  /* lanzar la llamada cada vez que cambie el idioma del usuario! */
      this.getData()
    }
  },
  methods:{
    getData(){
      this.loading = true
      let data = {
        'user_id':  this.$store.getters['user/getUserData'].id,
        'lang': this.$store.getters['user/getLang'],
        'token': this.$store.getters['user/getUserData'].sso,
      }
      
      const promises = [
        this.getDataContents(data),
        this.getDataHierarchy(data),
        this.getDataLPs(data),
        this.getDataAllocation(data),
        this.getDataGroups()
      ];

      Promise.allSettled(promises).then((results) =>
        {
          this.loading = false;
          this.getDataRecommended(data)
        }
      );
    },
    async getDataRecommended(data){
      await this.$http.get(getRecommendedLearningPath, {params: data}).then( response => {
        /* let my_lp_rec = [{
            "id": 99962306,
            "img": 1,
            "name": "francesa recomendada",
            "description": "",
            "hierarchy": [343,368, 294, 319],
            "contents": [
                13,
                699,
                700,
                701,
                702
            ],
            "source": [
              "own",
                "smileandlearn",
                "recommended"
            ]
        }]
        console.log("guardando LP recomendada") */
        this.$store.commit('learningPaths/setRecommendedLearningPaths', response.data)
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    getDataContents(data){
      return new Promise((resolve, reject) => {
        this.$http.get(getContents, {params: data}).then( response => {
          this.$store.commit('learningPaths/setContents', response.data)
          return resolve(true)
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
          return reject(error)
        })
      })
    },
    getDataHierarchy(data){
      return new Promise((resolve, reject) => {
         this.$http.get(getHierarchy, {params: data}).then( response => {
            if(this.$store.getters['user/getUserData'].id == 1081){
              let myHierarchy = [
                { "source":"smileandlearn", "subcategories": response.data } 
              ]
              this.$store.commit('learningPaths/setHierarchy', myHierarchy )
            } else {
              let myHierarchy = [
                { "source":"own" },
                { "source":"school" },
                { "source":"shared" },
                { "source":"recommended" },
                { "source":"smileandlearn", "subcategories": response.data } 
              ]
              this.$store.commit('learningPaths/setHierarchy', myHierarchy )
            }
            return resolve(true)
          }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
          return reject(error)
        })
      })
    },
    getDataLPs(data){
      return new Promise((resolve, reject) => {
        this.$http.get(getLearningPath, {params: data}).then( response => {
          this.$store.commit('learningPaths/setLearningPaths', response.data)
          return resolve(true)
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
          return reject(error)
        })
      })
    },
    getDataAllocation(data){
      return new Promise((resolve, reject) => {      
        this.$http.get(getAllocation, {params: data}).then( response => {
          this.$store.commit('learningPaths/setAllocation', response.data)
          return resolve(true)
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
          return reject(error)
        })
      })
    },
    getDataGroups(){
      return new Promise((resolve, reject) => {
        this.$http.get(getGroup+'?limit=0').then( response => {
          this.$store.commit('learningPaths/setDreamersByGroup', response.data.groups)
          return resolve(true)
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            return reject(error)
        })
      })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    }
  },
  beforeRouteLeave (to, from , next) {
    if(to.path !='/login'){
      if(this.$store.getters['learningPaths/getAllocationHaveChanged']){
        //const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
        this.$bvModal
            .msgBoxConfirm(this.$t('forms.unsaved_changes'), {
              cancelVariant: 'outline-secondary',
              okTitle: this.$t('Yes'),
              cancelTitle: this.$t('No'),
            })
            .then(answer => {
              if (answer) {
                next()
              } else {
                next(false)
              }
            })
        
        
      } else{
        next()
      }
    } else {
      next()
    }
  }
}
</script>

<style>

</style>