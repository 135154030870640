<template>
  <b-card no-body class="mt-1">
    <b-card-header
      v-if="lp.name"
      class="email-detail-head"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <b-avatar
          size="48"
          variant="light"
          :src="require('@/assets/images/learning_paths/'+lp.img+'.png')"
          class="mr-75"
        />
        <div class="mail-items">
          <h5 class="mb-25">
            {{ lp.name }}
          </h5>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
      </div>
    </b-card-header>

    <b-card-body class="mail-lp-wrapper pt-2">
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="lp.description"
        class="mail-lp mb-1"
        v-html="lp.description"
      />
      <b-alert
       v-if="lp.explanation"
        variant="info"
        class="mb-1"
        show
      >
        <div class="alert-body">
          <feather-icon
            size="25"
            class="mr-25"
            icon="StarIcon"
          />
          <span class="ml-25">
          {{lp.explanation}}
          </span>
       </div>
      </b-alert>
      <div>
        <feather-icon
            size="15"
            class="mr-25 text-primary"
            icon="TagIcon"
          />
          <template v-if="lp.hierarchy">
            <b-badge
              v-for="hierarchy_name in filterHierarchy()"
              :key="hierarchy_name"
              class="text-capitalize mr-50 mb-25"
              variant="light-primary"
            >
              {{ hierarchy_name }}
            </b-badge>
            </template>
            <b-badge v-for="s in lp.source" :key="s"
              class="text-capitalize mr-50"
              :variant="resolveSourceVariant(s)"
            >
              {{ $t('learning_path.'+s) }}
            </b-badge>
      </div>
      <!-- eslint-enable -->
       
    </b-card-body>



  </b-card>
</template>

<script>
import {
  BDropdown, BDropdownItem, BAlert, BBadge, BCard, BCardHeader, BCardBody, BCardFooter, BAvatar, BLink, BImg,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BDropdown, BDropdownItem, BAlert, BBadge, BCard, BCardHeader, BCardBody, BCardFooter, BAvatar, BLink, BImg,
  },
  props: {
    lp: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
   methods:{
    filterHierarchy(){
      let hierarchies = []
      this.lp.hierarchy.map(h => {
        hierarchies.push(this.resolveHierarchy(h)) 
      })
      
      function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      }
      var unique = hierarchies.filter(onlyUnique);
      return  unique
    },
    resolveHierarchy(h_id){
      let data = this.$store.getters['learningPaths/getSmileHierarchy']

      function dfs(obj, targetId) {
        if (obj.id === targetId) {
          return obj.name
        } else {

          if (obj.subcategories) {
            for (let item of obj.subcategories) {
              let check = dfs(item, targetId)
              if (check) {
                return check
              }
            }
          }
          return null 
        }
      }

      let result = null

      data.every(obj => {
        result = dfs(obj, h_id)
          if (result) {
            return false
          }
          return true
      });

      return result
    },
  }
}
</script>

<style>

</style>
