<template>
<div :style="nodeMargin">
  <div @click="toggleChildren">
    <b-alert :variant="hasChildren?'secondary':'primary'" show 
      :class="['pointer', hasChildren?'':'tree-leaf']" >
      <div class="alert-body d-flex justify-content-between">
      {{ node.name }}
      
      <feather-icon
        v-if="hasChildren"
        :icon="toggleChildrenIcon"
        size="17"
        
      />
      </div>
    </b-alert>
    </div>
    <div v-if="hasChildren" v-show="showChildren">
      <TreeNode
        v-for="child in node.subcategories"
        :key="child.id"
        :node="child"
        :spacing="10"
        @selected-node="selectedNode"
      />
    </div>
  </div>
</template>
<script>
import TreeNode from './TreeNode.vue'
import { BAlert} from 'bootstrap-vue'

export default {
  name: 'TreeNode',
  components:{
    TreeNode,
    BAlert
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    spacing: {
      type: Number,
      default: 0
    }
  },
   data() {
    return {
      showChildren: false
    }
  },
  computed: {
    nodeMargin() {
      return {
        'margin-left': `${this.spacing}px`
      }
    },
    hasChildren() {
      const { subcategories } = this.node
      return subcategories && subcategories.length > 0
    },
    toggleChildrenIcon() {
      return this.showChildren ? 'ChevronDownIcon' : 'ChevronRightIcon'
    }
  },
  methods: {
    toggleChildren() {
      const { subcategories } = this.node
      //// console.log(this.node)
      if(subcategories && subcategories.length > 0){
        //àss
      }else{
        // console.log("mmm", this.node.name)
        this.$emit('selected-node', this.node)
      }
      this.showChildren = !this.showChildren
    },
    selectedNode(node){
      this.$emit('selected-node', node)
    }
  }
}
</script>

<style lang="scss" scoped>

.tree-leaf :active{
  background-color:white !important;
  border-radius: 0.358rem;
  color: #3a71a3 !important;
}
</style>
